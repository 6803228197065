import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { GenericListApiResponse, GenericItemApiResponse, ApiResponse } from 'src/app/core/_interface/apiResponse';
import { IModifier } from 'src/app/core/_interface/modifier';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ModifierService {
  modifierSaved = new Subject<IModifier>();
  modifierDeleted = new Subject<IModifier>();

  constructor(private http: HttpClient) {

  }


  get(companyID: number): Observable<IModifier[] | undefined> {
    const url = environment.apiBase + `modifier/get?companyID=${companyID}`;
    return this.http.get<GenericListApiResponse<IModifier>>(url)
      .pipe(
        map(response => {
          if (response.success) {
            return response.items;
          }
          return undefined;
        })
      );
  }

  save(companyID:number, modifier: IModifier): Observable<IModifier | undefined> {
    const url = environment.apiBase + `modifier/save?companyID=${companyID}`;
    return this.http.post<GenericItemApiResponse<IModifier>>(url, modifier)
      .pipe(
        map(response => {
          if (response.success) {
            return response.item;
          }
          return undefined;
        }),
        tap(s =>{
          if (s){
            this.modifierSaved.next(s);
          }
        })
      );
  }


  delete(suggestion: IModifier) : Observable<boolean> {
    const url = environment.apiBase + `modifier/delete`;
    return this.http.post<ApiResponse>(url, suggestion)
      .pipe(
        map(response =>  response.success),
        tap(s => this.modifierDeleted.next(suggestion))
      );
  }

}
